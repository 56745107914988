.referral-code-container {

    max-width: 600px;
    margin: 0 auto;
    padding: 70px 20px;
    text-align: center;
  }
  
  .referral-code {
    border: 1px solid #1AA79E;
    border-radius: 5px;
    padding: 20px;
    background-color: #f7f7f7;
  }
  
  .refer-1code {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1AA79E;
    font-weight: bold;
  }
  
  .refer-2code {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: #1AA79E;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content:center ;
  }
  
  .refer-2code:hover {
    background-color: #14756F;
  }
  