
.delivery-address-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.delivery-address-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.delivery-address-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.delivery-address-field {
  margin-bottom: 20px;
}

.delivery-address-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.delivery-address-field input[type="text"],
.delivery-address-field input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.delivery-address-field input[type="number"] {
  -moz-appearance: textfield;
}

.delivery-address-button {
  text-align: center;
  margin-top: 20px;
}

.delivery-address-button button {
  padding: 16px 25px;
  background-color: #1AA79E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

/* Dropdown Styles */
.delivery-address-dropdown {
  grid-column: span 2; /* Span 2 columns */
  margin-bottom: 20px; /* Adjust as needed */
}

.delivery-address-dropdown select {
  width: calc(100% - 22px); /* Adjust width to fit grid gap */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
}

.address-list {
  margin: 20px;
  display: flex;
  justify-content: space-evenly;
}

.address-item {
  /* border: 1px solid #ccc; */
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 0px #dbdbdb;
}

.address-item:hover {
  background-color: #eaeaea;
}

.display-mode {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 600px; /* Adjust the max-width as needed */
  overflow: hidden; /* Hide overflow content */
  padding: 10px; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.display-mode > div {
  margin-bottom: 5px;
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; 
  white-space: nowrap; 
}
.display-mode button {
  margin-top: 10px;
  padding: 5px 6px;
  background-color: #1AA79E;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 50%;
  position: absolute;
  left: 90%;
  top: -2px;
}

.display-mode button:hover {
  background-color: #179088;
}

@media (max-width:768px) {
  .address-list{
    flex-direction: column;
  }

  .address-item{
    width: 100%;
  }
}