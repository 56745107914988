.extendorder {
    margin: 30px auto; /* Center the element horizontally */
    max-width: 1000px; /* Limit the maximum width of the element */
    padding: 20px; /* Add padding for better spacing */
    background-color: #cecece;
    border-radius: 10px;
}

.extendorder-1 {
    display: flex;
    gap: 40px;
    padding: 20px;
}

.extendorder-1 img {
    max-width: 200px; /* Limit the maximum width of images */
    height: auto; /* Maintain aspect ratio */
}

.extendorder-3 {
    display: flex;
    justify-content: space-between;
    background-color: rgb(237, 242, 242);
    padding: 10px;
    margin-top: 20px; /* Add some space at the top */
    border-radius: 5px; /* Add border radius for a softer look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}


.extend-date {
    margin: 20px auto; /* Center the input horizontally with some margin */
    max-width: 500px; /* Limit the maximum width of the input */
  }
  
  .extend-date label {
    font-size: 18px; /* Adjust font size as needed */
    color: #333; /* Adjust color as needed */
    display: block;
    margin-bottom: 10px; /* Add some space below the label */
  }
  
  .extend-date input[type="date"] {
    width: 100%; /* Take up full width of its container */
    padding: 12px; /* Increase padding for better visual appearance */
    font-size: 16px; /* Adjust font size as needed */
    border: 1px solid #ccc; /* Add a subtle border */
    border-radius: 8px; /* Round the corners */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    transition: border-color 0.3s ease; /* Add smooth transition for border color */
  }
  
  .extend-date input[type="date"]:focus {
    outline: none; /* Remove default focus outline */
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a box shadow on focus */
  }
  
  /* Style the placeholder text */
  .extend-date input[type="date"]::placeholder {
    color: #999; /* Adjust placeholder text color */
  }
  
 .extend-order-details-main{
    margin: 30px;
  }
  
  .extend-order-details {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
   
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .extend-order-details > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .extend-order-details h4 {
    margin: 0;
    font-size: 1.4rem;
    color: #333;
  }
  
  .extend-order-details p {
    margin: 0;
    font-size: 1rem;
    color: #666;
  }
  
  .extend-order-details hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 10px 0;
  }
  
  .extend-order-details-5 {
    font-weight: bold;
    /* color: #1AA; */
  }
  
  .extend-order-details-5 p{
    font-size: 1.2rem;
    color: #1AA;
  }
  

  .extend-order-booknow{
    text-align: center;

    margin: 20px;
  }

  .extend-order-booknow button{
    border: none;
    background-color: #1AA79E;
    color: white;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    border-radius: 10px;
  }