.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 10px;
    padding-bottom: 30px;
  }
  
  .signup-section {
    width: 100%;
    max-width: 400px;
  }
  
.address-fetch{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.address-fetch button{
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.location-buttons{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
  
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .form-input {
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    transition: border-color 0.3s;
    width: 100%;
  }
  
  .form-input:focus {
    border-color: #13b7ac;
  }
  
  .submit-btn {
    padding: 12px 20px;
    background-color: #1AA79E;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-btn:hover {
    background-color: #139188;
  }
  
  .error-message{
    color: red;
  }

  .signup-image{
    text-align: center;
    margin: 20px;
  }

  .mobilenumberforotpnew{
    display: flex;
   border: 1px solid #ccc;
   border-radius: 6px;
  }

  .form-input3 {
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 6px;
  }
  .form-input3:focus{
    outline: none;
  }
  .mobilenumberforotpnew button{
    display: flex;
    border: none;
    align-items: center;
    background-color: #fff;
    color: #1AA79E;
    border-radius: 6px;
    width: 100px;
  }

  @media (max-width:768px) {
    .mobilenumberforotpnew button{
    
      width: 110px;
    }
  }

  .resend-otp-2:hover{
    color: blue;
    text-decoration: underline;
  }
  .resend-otp-2:active,
  .resend-otp-2:focus
  {
    color: blue;
    text-decoration: underline;
  }