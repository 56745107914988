.form-otp {
  width: 290px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease-in-out;
  
}

.form-otp:hover {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  scale: 0.99;
}

.heading-otp {
  text-align: center;
  color: black;
  font-weight: bold;
}

.check-otp {
  align-self: center;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-otp {
  width: 2.5em;
  height: 2.5em;
  margin: 1em;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgb(235, 235, 235);
  box-shadow: inset 3px 3px 6px #d1d1d1, inset -3px -3px 6px #ffffff;
  padding-left: 15px;
  transition: 0.4s ease-in-out;
}

.input-otp:hover,
.input-otp:focus {
  box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
  background-color: lightgrey;
}

.btn1-otp,
.btn2-otp {
  width: 17em;
  height: 3em;
  border-radius: 5px;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 1px 3px #b5b5b5, -1px -1px 3px #ffffff;
  cursor: pointer;
}

.btn1-otp:active,
.btn2-otp:active {
  box-shadow: inset 3px 3px 6px #b5b5b5, inset -3px -3px 6px #ffffff;
}

.btn1-otp {
  margin-top: 1em;
}

.btn2-otp {
  margin-top: 0.5em;
}


.otp-section{
width: 25%;
margin: auto;
padding-top: 20px;
padding-bottom: 20px;

}
.resend-otp-1:hover{
  color: blue;
  text-decoration: underline;
}
.resend-otp-1:active,
.resend-otp-1:focus
{
  color: blue;
  text-decoration: underline;
}

@media (max-width:768px) {
  .form-otp{
    margin: 10px -94px;
  }
}
@media (max-width:290px) {
  .form-otp{
    width: 270px;
  height: 340px;
    margin: 10px -100px;
  }
}