.newhome-section {
  background-color: #DEE9FF;
  width: 100%;
  /* display: flex;
  justify-content: space-between; */
}

.swiper {
  width: 100%; 
  max-width: 450px; 
  height: 100%;
}


@media screen and (max-width: 768px) {
  .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 20px 20px 0px 0px;
  }
}

@media screen and (max-width: 460px) {
  .swiper-slide {
      width: 100%; 
  }
  .mySwiper {
      width: 100%;
      margin-left: 0; 
  }
}

@media screen and (max-width: 440px) {
  .new-text-newhome {
    width: 261px !important;
    margin-left: -120px !important;
    margin-top: 90px !important;
   }
  .mySwiper {
    width: 300px !important;
      margin-left: -120px !important; 
  }
}

@media screen and (max-width: 420px) {
  .new-text-newhome {
    width: 261px !important;
    margin-left: -120px !important;
    margin-top: 90px !important;
   }
  .mySwiper {
    width: 300px !important;
      margin-left: -120px !important; 
  }
}



@media screen and (max-width: 400px) {
  .swiper-slide img {
      width: 100%;
      border-radius: 20px 20px 0px 0px;
  }
  .swiper-slide {
      width: 100%; 
  }
  .mySwiper {
      width: 100%;
      margin-left: 0; 
  }
}

@media screen and (max-width: 380px) {
  .new-text-newhome {
    width: 319px !important;
    margin-top: 47px;
    margin-left: 0px !important;
}
  .swiper-slide img {
      width: 100%;
      border-radius: 20px 20px 0px 0px;

  }
  .swiper-slide {
      width: 100%; 
  }
  .mySwiper {
      width: 100%;
      margin-left: 0px !important; 
  }
}
@media screen and (max-width: 290px) {
  .new-text-newhome {
    width: 200px !important;
    margin-top: 47px;
    margin-left: 0px !important;
}
  .swiper-slide img {
      width: 100%;
      border-radius: 20px 20px 0px 0px;

  }
  .swiper-slide {
      width: 100%; 
  }
  .mySwiper {
      width: 250px !important;
      margin-left: 0px !important; 
  }
  .newhome-section-1{
    margin: 2px 8px;
  }
  
  .coupon-container{
    height: 180px;
  }
  .coupon-title{
    flex-direction: row !important;
    text-align: left !important;
    padding: 0px !important;
  }
  .coupon-title p{
    font-size: 12px !important;
  }
  .coupon-title img{
    width: 35px !important;
    height: 35px !important;
  }
  .coupon-content{
    flex-direction: row !important;
    align-items: center !important;
  }
  .coupon-content{
    margin: -15px 0px;
    gap: 14px !important;
  }
  .coupon-validity h2{
   font-size: 15px !important;
  }
  .coupon-code-value{
    font-size: 13px !important;
  }
  .coupon-code{
    width: 50% !important;
  }

  .newhome-popular-service {
    margin: 25px !important;
  }
}

@media screen and (max-width: 460px) {
  .new-text-newhome {
      width: 261px;
      margin-top: 47px;
      margin-left: -100px;
  }

  .coupon-container{
    height: 180px;
  }
  .coupon-title{
    flex-direction: row !important;
    text-align: left !important;
    padding: 0px !important;
  }
  .coupon-title p{
    font-size: 12px !important;
  }
  .coupon-title img{
    width: 35px !important;
    height: 35px !important;
  }
  .coupon-content{
    flex-direction: row !important;
    align-items: center !important;
  }
  .coupon-content{
    margin: -15px 0px;
    gap: 14px !important;
  }
  .coupon-validity h2{
   font-size: 15px !important;
  }
  .coupon-code-value{
    font-size: 13px !important;
  }
  .coupon-code{
    width: 50% !important;
  }
}
@media (max-width:830px) {
  .coupon-container{
    width: 100% !important;
  }
}
.swiper-slide {
    background-position: center;
    background-size: cover;
   background-color: #DEE9FF;
}

  
  .swiper-slide img {
    display: block;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
  

  .new-text-newhome{
    display: flex;
    flex-direction: column;
  }


  
  .newhome-section-sub {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: center;
    padding:    0 12rem;
  }
  
  .new-text-newhome {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 45%; /* Adjust the width as needed */
  }
.new-text-newhome-1 h6{
    font-size: 20px;
    font-weight: 750;
    color: #1AA79E;
}
  .new-text-newhome-2 h1{
    font-size: 50px;
  }

  .new-text-newhome-3 p{
    color: #7A7A7A;
  }
  
  .new-slider-newhome {
    width: 45%; /* Adjust the width as needed */
  }
  
  .new-slider-newhome .mySwiper {
    width: 100%;
  }
  .newhome-fast-moving-4{
    text-align: center;
  }
  @media (max-width: 768px) {

    .new-text-newhome {
  
      width: 100%;
   
    }
    .new-slider-newhome {
      width: 100%; 
    }
    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 20px 20px 20px 20px;
      margin-bottom: 10px;
    }
    .newhome-section-sub {
      flex-direction: column;
      align-items: center;
    }
    .new-text-newhome-2 h1{
      font-size:25px;
    }
    .new-text-newhome-3 p{
      font-size: 10px; 
     }
  }

  @media (max-width: 380px) {
    .new-slider-newhome {
      width: 100%;
      margin: 10px; 
    }
    .newhome-section-sub {
     padding: 20px;
     margin-right: 30px;
    }
    
  }
#newhome-section-truck {
  display: flex;
  align-items: center; 
  gap: 10px;
  margin: 40px;
}

.newhome-section-truck-img img {
  width: 20px;
}

.newhome-section-truck-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newhome-section-truck-text h3 {
  font-size: 10px; 
}

.newhome-section-truck-text p {
  color: #7A7A7A;
  font-size: 14px; 
}

.newhome-section-1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  place-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px; 
  /* margin: 60px 20px;  */
}

@media screen and (min-width: 768px) {
  .newhome-section-1 {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    text-align: center;
    gap: 20px; 
    /* margin: 40px 120px;  */
  }
  .newhome-section-truck-img img {
    width: 30px;
  }
  .newhome-section-truck-text h3 {
    font-size: 16px; 
  }
}

.newhome-section-3{
    display: flex;
    align-items: center;
    gap: 100px;
    width: 100%;
    padding: 100px;
    margin: 20px;
}
.newhome-section-3-sub-1-img {
    margin-top: -40px; 
}

.newhome-section-3-sub-1-img img {
    width: 200px;
}

.newhome-section-3-sub-1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 45%;
    background-color: #DEE9FF;
    border-radius: 10px;
    padding: 0px 20px 0px 20px;
   
}
.newhome-section-3-sub-1 h1{
  color: black;
}
.newhome-section-3-sub-1 h1 span{
  color: #1AA79E;
}
.newhome-section-3-sub-2 {
    position: relative; 
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px; 
    background-size: cover;
    height: 320px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.newhome-section-3-sub-2::before {
    content: ''; 
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(3, 61, 26, 0.469);
    border-radius: 10px; 

}
@media (max-width:768px) {
  .newhome-section-3{
    flex-direction: column;
    margin: 0;
}
.newhome-section-3-sub-1 {
  width: 300px;

}
.newhome-section-3-sub-1-img {
  margin-top: -50px; 
}

.newhome-section-3-sub-1-img img {
  width: 130px;
}
.newhome-section-3-sub-1 {
  justify-content: space-between;
}
}
.newhome-section-4-sub-1-text-1,
.newhome-section-4-sub-1-text-2 {
    position: relative;
    z-index: 1; 
    color: white; 
}
.newhome-section-4-sub-1-text-1{
    display: flex;
    justify-content: space-between;

}
.newhome-section-4-sub-1-text-1 img{
    width: 30px;
}
.newhome-section-4-sub-1-text-2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.newhome-section-4-sub-1-text-2 h1{
   font-size: 60px;
}

.newhome-section-4-sub-1-text-2 button{
    border: none;
    padding: 16px 32px 16px 32px;
    border-radius: 100px;
}

.newhome-popular-service {
    display: flex;
    justify-content: space-evenly;
    gap: 2rem; 
    margin: 80px;
}

.newhome-popular-service-1 {
    color: #1AA79E;
    font-weight: 700; 
}

.newhome-popular-service-2 h1 {
    color: black;
    font-size: 2.5rem; 
    margin-bottom: 0.5rem; 
    width: 55%;
}

.newhome-popular-service-2 p {
    color: #7A7A7A;
    font-size: 1rem; 
    margin-bottom: 1rem; 
    width: 70%;
}

.newhome-popular-service-3 {
    padding: 1rem; 
    border: 1px solid gray;
    width: 190px; 
    height: 160px; 
    border-radius: 50%; 
    display: flex;
    justify-content: center; 
    align-items: center; 
}

@media (max-width: 768px) {
  .newhome-popular-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem; 
    margin: 50px ;
}
.newhome-popular-service-2 h1 {
  color: black;
  font-size: 1.5rem; 
  margin-bottom: 0.5rem; 
  width: 100%;
}
.newhome-popular-service-3 {

  width: 120px; 
  height: 120px; 
 
}
}

.popular-services{
    margin: 40px;
}

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(272px, 1fr)); /* Adjust as needed */
    gap: 40px;
    transition: height 0.5s ease-in-out; 
 
  }
  
  .item {
     display: flex;
     flex-direction: column;
     gap: 20px;
     box-shadow: 1px 1px 22px 1px rgb(234, 231, 231);
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    border-radius: 15px;
    text-align: center;
  }
  .item.show {
    opacity: 1;
    transform: translateY(0);
}
  .item-content {
    width: 100%;
    height: 100%;
  
  }
  
  .image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
    transition: all ease-in-out 0.3s;
  
  }
  .image:hover {
    transform: scale(1.05);
    overflow: hidden;
  }
  .text {
   
    font-weight: 600;
    font-size: 23px;
   
    color: black; 
  }
  
  .newhome-whyus-section {
    display: flex;
    justify-content: space-between; 
    flex-wrap: wrap; 
    gap: 20px;
    margin: 60px auto; 
    max-width: 1200px; 
  }
  .newhome-whyus{
    color: #1AA79E;
  }
  .our-commitment-to-quality {
    width: calc(33.33% - 20px);
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .our-commitment-to-quality h3{
          font-size: 40px;
          font-weight: 600;
  }
  .unparalel-quality,
  .fast-shipping,
  .trust-and-reliability {
    display: flex;
    align-items: center; 
    gap: 10px;
  }
  
  .unparalel-quality img,
  .fast-shipping img,
  .trust-and-reliability img {
    width: 100px;
    height: 60px;
  }
  .unparalel-quality-1 h5,
  .fast-shipping-1 h5,
  .trust-and-reliability-1 h5{
    font-weight: 600;
    font-family:Arial, Helvetica, sans-serif;
  }
  .unparalel-quality-1 p,
  .fast-shipping-1 p,
  .trust-and-reliability-1 p{
    color: #7A7A7A;
  }
  .brobo-black-img img {
    width: 100%; 
    max-width: 400px; 
  }
  @media screen and (max-width: 768px) {
    .our-commitment-to-quality {
      width: 100%; 
    }
      
  .newhome-whyus-section {
 
    margin: 30px ; 
   
  }
  .grid {
   
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr)); /* Adjust as needed */
    gap: 25px;
  
 
  }
  .image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 15px;
    transition: all ease-in-out 0.3s;
  
  }
  .text {
   
    font-weight: 600;
    font-size: 18px;
   
    color: black; 
  }
  }

  .newhome-coupon-code-1 img{
   width: 98vw;
  }
  /* .newhome-coupon-code-1{
    display: flex;
    justify-content: space-evenly;
    align-items:center ;
    gap: 2rem; 
    margin: 80px;
  } */
  .newhome-coupon-code-2{
    color: #BCBCBC;
    font-weight: 700;
    width: 200px; 
  }

  .newhome-coupon-code-3 h1 {
    color: white;
    font-size: 2.5rem; 
    margin-bottom: 0.5rem; 
    width: 75%;
}

.newhome-coupon-code-3  p {
  color: #BCBCBC;
  font-size: 1rem; 
  margin-bottom: 1rem; 
  width: 55%;
}
  .newhome-coupon-code-4{
    padding: 1rem; 
    border: 1px solid white; 
    width: 200px; 
    height: 180px;
    border-radius: 50%; 
    display: flex;
    justify-content: center; 
    align-items: center;
  }
.newhome-coupon-code-5{
  color: white;
}
  .coupon-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: white;
    width: 80%;
    margin: 40px auto;
  }
  
  .coupon-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    text-align: left; 
  }
  .coupon-title p{
    font-size: 25px
  }
  .coupon-title img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    cursor: pointer;
  }
  
  .coupon-content {
    padding: 10px;
    border-radius: 4px;
    display: flex;
  
    justify-content: space-around; 
    align-items: center;
  }
  
  .coupon-code {
    background-color: rgba(0, 0, 0, 0.5);
    margin-bottom: 8px;
    font-size: 18px;
    border: 2px dashed white;
    border-radius: 10px;
    padding: 5px;
    width: 50%; 
  }
  
  .coupon-code-value {
    margin-bottom: 12px;
    font-size: 25px;
    font-weight: 700;
  }
  
  .coupon-validity h2 {
    font-size: 80px;
    margin: 0; 
  }
  
  .coupon-validity p {
    font-size: 25px;
    margin: 0; 
  }
  
  .coupon-validity {
    margin-top: 20px;
    font-size: 16px;
    text-align: left;
  }
  
  .coupon-code-section-main{
    background-color: #036D62;
    padding: 50px;
    margin: 40px auto;
  }

  @media screen and (max-width: 768px) {
    .newhome-coupon-code-1 {
      flex-direction: column; 
      gap: 1rem; 
      margin: 50px auto; 
    }
  
    .newhome-coupon-code-2,
    .newhome-coupon-code-3 h1,
    .newhome-coupon-code-4 {
      width: 100%; 
    }
  
    .coupon-title {
      flex-direction: column; 
      text-align: center; 
    }
  
    .coupon-title img {
      width: 50px;
      height: 50px;
      margin: 0 auto 20px; 
    }
  
    .coupon-content {
      flex-direction: column;
    }
  
    .coupon-code {
      width: 80%; 
    }
    .newhome-coupon-code-4{
   
      width: 200px; 
      height: 180px;
      border-radius: 50%; 
  
    }
    .coupon-container {
 
      width: 100%;
      margin: 80px auto;
    }
    .coupon-validity h2 {
      font-size: 30px;
      margin: 0; 
    }
    .coupon-validity p {
      font-size: 15px;
      margin: 0; 
    }
    .coupon-code-value {
 
      font-size: 20px;
 
    }
    .coupon-code {
      font-size: 13px;
    }
    .coupon-title p{
      font-size: 15px
    }
  }


  .newhome-fast-moving-3{
    padding: 1rem; 
    border: 1px solid gray; 
    width: 170px; 
    height: 160px;
    border-radius: 50%; 
    display: flex;
    justify-content: center; 
    align-items: center;
  }