
.order-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
}
.order-details-heading{
    display: flex;
    gap: 5px;
    justify-content: space-between;
}
.order-details-head-btn{
    color: red;
    border: none;
    background: none;
    font-size: 25px;
}

.ongoing-items{
    display: flex;
    justify-content: space-between;
}

.ongoing-items1{
  font-weight: bold;
}

.ongoing-shippingaddress{
    display: flex;
    gap: 5px;
}
.ongoing-shippingaddress1{
    font-weight: bold;
}
.ongoing-estimateddelivery{
    display: flex;
    gap: 5px;
}
.ongoing-estimateddelivery1{
    font-weight: bold;
}

.ongoing-enddate{
    display: flex;
    gap: 5px;
}
.ongoing-enddate1{
    font-weight: bold;
}

.ongoing-timeslot{
    display:flex;
    gap: 5px;
}
.ongoing-timeslot1{
    font-weight: bold;
}

.order-details-extend{
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.extend-order-btn{
    border: none;
    padding: 10px;
    background-color: #ccc;
    border-radius: 10px;
}