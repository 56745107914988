/* ContactUs.css */

.contact-us-container {
    margin: 50px auto;
    padding: 20px;
    max-width: 600px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .icon {
    margin-right: 10px;
    font-size: 24px;
  }
  
  /* Additional styles for icons */
  .icon {
    width: 24px;
    height: 24px;
  }
  