.my-bookings-container {
    margin: 20px;
  }
  
  .booking-options {
    display: flex;
    gap: 10px;
  }
  
  .option-btn {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .option-btn.active {
    background-color: #ddd;
  }
  
  .booking-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Add more CSS styles as needed */
.ongoing-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ccc;
    padding: 10px;
    border-radius: 10px;
    /* width: 50%; */
}

  .ongoing-description{
    display: flex;
    /* align-items: center; */
    /* flex-wrap: wrap; */
  }
.ongoing-description1{
    font-weight: bold;
}
  .ongoing-order{
    display: flex;
    justify-content: space-between;
  }
  .ongoing-order1{
    font-weight: bold;
}
  .ongoing-arriving{
    display: flex;
    justify-content: space-between;
  }

  .ongoing-arriving1{
    font-weight: bold;
}
  .ongoing-total{
    display: flex;
    justify-content: space-between;
  }

  .ongoing-total1{
    font-weight: bold;
}

.booking-list{
    color: black;
    text-decoration: none;
}