/* .product-details {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.product-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}



.main-image {
  width: 600px;
  height: auto;
}

.thumbnail-image {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto; 
  gap: 10px;
  padding-bottom: 10px; 
  margin-bottom: 20px;
  -webkit-overflow-scrolling: touch; 
}

.thumbnail {
  width: 70px; 
  height: 70px; 
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}



.color-thumbnail {
  margin-bottom: 10px;
  cursor: pointer;
}

.color_img {
  margin-bottom: 5px;
}

.color-icon {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
 
}

.color-icon.active {
  border-color: #000;
}




.product-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  max-width: 450px;
}

.product-info h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-info p {
  margin-bottom: 5px;
}

.price {
    color: darkgray;
  font-weight: 500;
}

.discounted-price {
  color: rgb(13, 184, 164);
  font-weight: bold;
}
.productdetails-price{
    display: flex;
    justify-content: space-between;
}


  .product-quantity1 {
    display: flex;
    align-items: center;
    gap: 10px;
  }

.product-quantity-btn-1,
.product-quantity-btn-2 {
  border: none;
  background-color: rgb(6, 134, 134);
  padding: 10px;
  border-radius: 8px;
}

.product-quantity-btn-1:hover,
.product-quantity-btn-2:hover {
  background-color: rgb(4, 87, 87);
}



.productprice {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}


.Add-to-cart {
  text-align: center;
  width: 300px;
}

.Add-to-cart-btn {
  border: none;
  background-color: rgb(6, 134, 134);
  width: 300px;
  padding: 12px;
  border-radius: 10px;
}

.Add-to-cart-btn:hover {
  background-color: rgb(10, 109, 109);
}

@media (max-width: 768px) {
  .product-details-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .main-image {
    width: 300px;
    height: auto;
  }
  .image-container {
    margin-right: 20px;
    margin-bottom: 20px; 
  }
  
}

  
  .Add-to-cart{
    text-align: center;
    width: 300px;
  }
  .Add-to-cart-btn{
    border: none;
    background-color: rgb(6, 134, 134);
    width: 300px;
    padding: 12px;
    border-radius: 10px;
  }

  .Add-to-cart-btn:hover{
    background-color: rgb(10, 109, 109);
  }




  
 
  .productprice{
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  @media (max-width: 768px) {
    .product-details-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .main-image {
      width: 300px;
      height: auto;
    }
  } */






  .product-details {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin: 20px;
}

.product-details-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.image-container {
    flex: 1;
}

.main-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border: 1px solid #ddd; /* Add border to main image */
    transition: transform 0.3s ease; /* Add transition effect for image hover */
}

.main-image:hover {
    transform: scale(1.1); /* Enlarge main image on hover */
}

.thumbnail-images {
    display: flex;
    flex-wrap: wrap;
    max-height: 300px; /* Limit thumbnail container height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.thumbnail-images img {
    width: 70px;
    height: 70px;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #ddd; /* Add border to thumbnails */
    transition: transform 0.3s ease; /* Add transition effect for thumbnail hover */
}

.thumbnail-images img:hover {
    transform: scale(1.1); /* Enlarge thumbnail on hover */
}

.product-info {
    flex: 1;
    max-width: 500px;
}

.product-info h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.description {
    margin-bottom: 20px;
    line-height: 1.5;
}

.productdetails-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.discounted-price {
    color: #b12704; /* Adjust color for discounted price */
    font-size: 24px;
    font-weight: bold;
}

.price {
    color: #757575; /* Adjust color for original price */
    font-size: 20px;
    text-decoration: line-through;
}

.stock {
    margin-bottom: 10px;
    font-size: 16px;
}

.subcategory {
    font-size: 16px;
    margin-bottom: 10px;
}

.product-quantity {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.product-quantity h6 {
    margin-right: 10px;
}
.product-quantity1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-quantity input {
  width: 50px;
  height: 30px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 16px;
  text-align: center;
}

.product-quantity input:focus {
  outline: none;
  border-color: #007bff;
}
.product-quantity-btn-1,
.product-quantity-btn-2 {
    border: none;
    background-color: #1AA; /* Amazon's add to cart button color */
    color: #111; /* Text color for add to cart button */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.product-quantity-btn-1:hover,
.product-quantity-btn-2:hover {
    background-color: #0d8383; /* Darken button color on hover */
}

.Add-to-cart-btn {
    border: none;
    background-color: #1AA; /* Amazon's add to cart button color */
    color: #111; /* Text color for add to cart button */
    width: 100%;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease; /* Add transition effect for button hover */
}

.Add-to-cart-btn:hover {
    background-color: #0d8383; /* Darken button color on hover */
}


.color-thumbnail img{
  width: 80px;
}

.color-thumbnails{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.color_img {
display: flex;
flex-direction: column;
align-items: center;
}

.recommend-section{
  margin: 30px;
}
.recoomend-product{
text-align: center;
  width: 230px !important;
}
.recoomend-product img{
   width: 230px;
   height: 230px;
}
@media (max-width: 768px) {
  /* .image-container{
    width: 400px;
  } */
  .product-details-container{
    display: flex;
    flex-direction: column;
    gap: 120px;
  }
 
    .recoomend-product img {
      width: 151px;
      height: 170px;
  }
  .recoomend-product{

      width: 151px !important;
    }
}


.toggle-available{
  display: flex;
  justify-content: space-between;
  align-items:center;
  background-color: #eae9e9;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  width: 100%;
  transition: all  0.3s; /* Transition for all properties */
}

.toggle-available-show{
  display: flex;
  justify-content: space-between;
  align-items:center;
  background-color: #eae9e9;
  padding: 15px;
  margin: 5px;
  border-radius: 10px;
  color: #1AA;
  width: 100%;
  transition: all 0.3s; /* Transition for all properties */
}

.bx-chevron-down{
  font-size: 30px;
}
.bx-chevron-right{
  font-size: 30px !important;
}