/* Notification Container */
.notification-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Notification Section */
  .notification-section {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
  }
  
  /* Notification List */
  .notification-list {
    list-style: none;
    padding: 0;
  }
  
  /* Notification Item */
  .notification-item {
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Notification Icon */
  .notification-icon {
    margin-right: 20px;
  }
  
  .notification-icon img {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    border-radius: 50%;
  }
  
  /* Notification Content */
  .notification-content {
    flex: 1;
  }
  
  .notification-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .notification-message {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    color: #666;
  }
  