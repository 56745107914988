.card {
    width: calc(100% - 40px); /* Adjust for margins */
    max-width: 300px; /* Adjust the max-width as needed */
    margin: 20px;
    background-color: #f8f9fa; /* Light gray background */
    border-radius: 10px;
    box-shadow: 1px 4px 8px 2px rgba(0, 0, 0, 0.1); 
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Position relative for absolute positioning */
    border: none;
}

.card-productlist-img {
    position: relative;
    overflow: hidden;
}

.card-img {
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover; /* Ensure the image covers the entire space */
}

.card-img-overlay1 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    color: #ffffff; /* White text color */
    padding: 10px;
}

.card-title {
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 0.5rem;
}

.card-productlist {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Adjust the min and max width as needed */
    gap: 20px; /* Spacing between cards */
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 8px 12px;
    margin: 0 5px;
    border: none; /* Remove border */
    border-radius: 5px;
    background-color: #018786; /* Primary color for buttons */
    color: #fff; /* Text color */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #1AA; /* Darker shade on hover */
  }
  
  .pagination button:disabled {
    opacity: 0.5; /* Reduced opacity for disabled buttons */
    cursor: not-allowed; /* Change cursor for disabled state */
  }
  
  .pagination span {
    margin: 0 10px;
    font-weight: bold;
    color: #333; /* Text color */
  }
  

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .card {
        width: calc(100% - 20px); /* Adjust for margins */
        max-width: calc(100% - 40px); /* Adjust the max-width as needed */
        margin: 10px; /* Reduce margin for smaller screens */
    }

    .card-img {
        height: 150px; /* Adjust image height for smaller screens */
    }

    .card-title {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }

    .card-productlist {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the min and max width as needed */
        gap: 20px; /* Spacing between cards */
        margin: 20px;
    }
}

.wishlist-icon {
    position: absolute;
    top: 0; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    z-index: 1; /* Ensure the icon is above other elements */
    color: red; /* Change color as needed */
    cursor: pointer; /* Add cursor pointer for interaction */
}

.wishlist-icon i {
    font-size: 24px; /* Adjust size as needed */
}

.sorted-menus{
    display:flex ;
    align-items: center;
    justify-content: space-around;
}
.Sorting-options{
    margin: 20px;
    border: none;
    padding: 10px;
    
}
@media (max-width:768px) {
    .Sorting-options{
        width: 110px;
        padding: 30px;
    }
    .sorted-menus{
        flex-wrap: wrap;
    }
}
.Sorting-options:focus{
    border: 1px solid gray;
    border-radius: 10px;
    
}





.cl-checkbox {
    position: relative;
    display: inline-block;
   }
   
   /* Input */
   .cl-checkbox > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
   }
   
   /* Span */
   .cl-checkbox > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
   }
   
   /* Box */
   .cl-checkbox > span::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    margin: 3px 11px 3px 1px;
    border: solid 2px;
    /* Safari */
    border-color: rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
   }
   
   /* Checkmark */
   .cl-checkbox > span::after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 5px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
   }
   
   /* Checked, Indeterminate */
   .cl-checkbox > input:checked,
   .cl-checkbox > input:indeterminate {
    background-color: #018786;
   }
   
   .cl-checkbox > input:checked + span::before,
   .cl-checkbox > input:indeterminate + span::before {
    border-color: #018786;
    background-color: #018786;
   }
   
   .cl-checkbox > input:checked + span::after,
   .cl-checkbox > input:indeterminate + span::after {
    border-color: #fff;
   }
   
   .cl-checkbox > input:indeterminate + span::after {
    border-left: none;
    transform: translate(4px, 3px);
   }
   
   /* Hover, Focus */
   .cl-checkbox:hover > input {
    opacity: 0.04;
   }
   
   .cl-checkbox > input:focus {
    opacity: 0.12;
   }
   
   .cl-checkbox:hover > input:focus {
    opacity: 0.16;
   }
   
   /* Active */
   .cl-checkbox > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
   }
   
   .cl-checkbox > input:active + span::before {
    border-color: #85b8b7;
   }
   
   .cl-checkbox > input:checked:active + span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.6);
   }
   
   /* Disabled */
   .cl-checkbox > input:disabled {
    opacity: 0;
   }
   
   .cl-checkbox > input:disabled + span {
    color: rgba(0, 0, 0, 0.38);
    cursor: initial;
   }
   
   .cl-checkbox > input:disabled + span::before {
    border-color: currentColor;
   }
   
   .cl-checkbox > input:checked:disabled + span::before,
   .cl-checkbox > input:indeterminate:disabled + span::before {
    border-color: transparent;
    background-color: currentColor;
   }
   
  
.sorted-menus{
    display: flex;
    justify-content: space-around;
}
  
.hideoutofstock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}
  
.search-filter input{
    padding: 8px;
    margin: 13px auto;
    border: 1px solid gray;
    border-radius: 10px;
    width: 50vw;
}  
  
.search-filter input:focus{
    border: none;
}
  
  
@media (max-width: 767px) {
    .Sorting-options{
        margin: 60px auto;
       padding: 5px;
    }
    
    .Sorting-options:focus{
        border: 1px solid gray;
        border-radius: 10px;
        
    }
    .hideoutofstock{
       font-size: 10px;
    }
}
  
  
  