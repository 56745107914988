.damage-order-btn{
    border: none;
    background-color: #179898;
    padding: 10px;
    width: 500px;
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
}

.damage-order-btn:hover{
background-color: #0f7e7e;
}