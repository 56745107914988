body {
  padding-top: 100px;
  margin: 0;
  
}
.new-header-section-1{
  
  position: fixed;
  top: 0; 
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
}
.new-header-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #DEE9FF;
    width: 100%; 
    padding: 0px 50px;

  }
  .new-header-2{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .new-header-2-brobo img {
    width: 100px;
  }
  .new-header-2-location img {
    width: 40px;
  }
  .new-header-2-drawer img{
    width: 40px;
  }
  .new-header-navlink-2{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .new-header-navlink-2 img{
      width: 40px;
  }
  .new-header-3 {
    display: flex;
    gap: 15px;
  }
  .new-header-navlink-1{
    display: flex;
    gap: 15px;
    align-items: center;
}
  .new-header-navlink-1 a {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease;
  }
  
  .new-header-navlink-1 a:hover {
    color: black;
    font-weight: 500;
  }
  
  .new-header-navlink-2 button {
    border: none;
    background-color: #1AA79E;
    padding: 12px 36px; 
    border-radius: 100px;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .new-header-navlink-2 h6 {
    border: none;
    background-color: #1AA79E;
    padding: 12px 36px; 
    border-radius: 100px;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: transform 0.5s;
  }
  .new-header-navlink-2 h6:hover{
    transform: scale(1.05);
  }
  @media (max-width: 1024px) {
    .new-header-1 {
      flex-wrap: wrap !important; 
    }
   
  }
  
  @media (max-width: 767px) {
    .new-header-1 {

      padding: 10px 20px; 
      gap: 10px;
      text-align: left;
    }
  
    .new-header-2 img {
      width: 80px; 
    }
  
    
    .new-header-3 {
     
      gap: 0; 
    }
  
    .new-header-navlink-2 button {
      padding: 10px 20px; 
      font-size: 12px; 
    }
    .new-header-navlink-1{
        display: flex;
      font-size: 14px;
        gap: 30px;
        align-items: center;
    }
    .new-header-3{
     
     flex-direction: column;
     gap: 10px;
  }
  .new-header-navlink-2 img{
    width: 30px;
}
body {
  padding-top: 160px;
  margin: 0;
  
}
  }
  
@media (max-width:290px) {
  .new-header-2 img {
    width: 60px; 
  }
  .new-header-navlink-2 h6 {
    padding: 6px 16px !important;
    font-size: 12px !important;
  }
  .new-header-navlink-2 button{
    padding: 65px 6px !important;
  }
  .new-header-navlink-1 {
    gap: 20px;
    font-size: 12px;
  }
  .new-header-2-location input[type="text"].show{
    width: 160px !important;
  }
}
  .side-nav-bar {
    background-color: #DEE9FF;
}
.side-nav-user-icon {
    border: none;
}

.side-nav-navlinks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    padding-right: 20px;
}

.side-nav-navlinks div {
    transition: all ease-in-out 0.2s;
}

.side-nav-navlinks div:hover {
    color: black;
    font-weight: 600;
}

.side-nav-navlinks a {
    text-decoration: none;
    color: black;
}

.side-nav-navlinks a:hover {
  color: black;
  font-weight: 600;
}



@media (max-width:768px) {
    .side-nav-bar {
        width: 200px !important;
    }

    .new-header-2-brobo img {
      width: 70px;
    }
    .new-header-2-location img {
      width: 30px;
    }
    .new-header-2-drawer img{
      width: 30px;
    }
    /* .new-header-1 {
      flex-direction: column;
      justify-content: center;
    } */
}



.dropdown {
  position: relative;
  display: inline-block;
  color: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1AA79E;
  color: #000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  touch-action: none;
}

.dropdown-content button {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
}

.dropdown-content button:hover {
  background-color: #24c2b8;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Adjust the positioning of the subcontent */
.dropdown-content .dropdown-subcontent {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 160px;
  background-color: #1AA79E;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Show subcontent when hovering over the dropdown item */
.dropdown-content:hover .dropdown-subcontent {
  display: block;
}


/* .dropdown-content a:hover + .dropdown-subcontent {
    display: block;
} */


.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Add transition for opacity */
}

.hidden {
  opacity: 0;
  height: 0px;
  transition: height 0.5s ease-in-out; /* Add transition for opacity */
  pointer-events: none; /* Ensure hidden element doesn't interfere with pointer events */
}



.notification-badge-container {
  position: relative;
  display: inline-block;
}



.notification-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #1AA79E;
  color: white;
  font-size: 10px;
  padding: 3px 6px;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
 
}


.new-header-2-location {
  position: relative;
  /* background-color: #fff;
  border-radius: 20px; */
  display: inline-block; /* Ensure the container only takes the size of its content */
}

.new-header-2-location input[type="text"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease, width 0.3s ease; /* Added transition for opacity and width */
  border-radius: 20px 2px 2px 20px;
  padding: 8px;
  width: 0;
  background-color: #fff; /* Set background color of input to match container initially */

}

.new-header-2-location img {
  width: 40px;
  cursor: pointer;
  z-index: 100;
}

.new-header-2-location input[type="text"].show {
  opacity: 1;
  width: 240px; /* Expand the input field when visible */
  background-color: #fff; /* Change background color when input is visible */
  padding-left: 40px;
}


.modal1 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 40vw;
  height: calc(100vh - 100%);
  z-index: 1;
  background-color: #fff;
}



.location-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.location-input-container img {
  margin-right: 8px;
}



.input-header-loc{
  border: none;
  padding: 10px;
  width: 300px;
}

.search-sheet-container-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure a high z-index to overlay other elements */
 
}


/* SearchComponent.css */

.search-sheet-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 1000;
  overflow-y: auto;
}

.search-sheet {
  width: 100%;
  /* max-width: 700px; */
  background-color: #fff;
  /* border-radius: 10px 10px 0 0; */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  animation: slideDown 0.3s ease-out;
}

.search-sheet-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bell-icon{
  cursor: pointer;
}
/* .search-sheet input[type="text"] {
  width: calc(100% - 70px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
} */

.search-sheet ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-sheet li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.search-sheet-header {
  padding: 10px;
  display: flex;
  
  justify-content: center;
  align-items: center;
}

.search-input-group {
  display: flex;
/* justify-content: space-between; */
  align-items: center;
  border: 2px solid #ccc;
  width: calc(100% - 700px);
}

@media (max-width:768px) {
  .search-input-group {

    width: calc(100% - 70px);
  }
  
}
.search-input-group input[type="text"] {
  width: calc(100% - 10px);
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
  font-size: 16px;
}

.search-input-group button {
  padding: 10px;
  border: none;
  background:none;
  color: #000;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: right;
}



.bx-search {
  font-size: 18px;
}

.search-resuly-list{
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.navlink-side-text{
  display: flex;
  gap: 10px;
  align-items: center;
}

