.profile-container {
    max-width: 800px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .profile-info {
    flex-grow: 1;
  }
  
  .profile-name {
    font-size: 28px;
    font-weight: bold;
  }
  
  .profile-bio {
    font-size: 18px;
    color: #666;
  }
  
  .profile-details {
    margin-top: 30px;
  }
  
  .profile-section {
    margin-bottom: 30px;
  }
  
  .profile-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .info-item {
    margin-bottom: 10px;
  }
  
  .info-item label {
    font-weight: bold;
  }
  
  .info-item span {
    color: #333;
  }
  
  .profile-edit-button{
    float: right;
  }
  .profile-edit-button button{
    border: none;
    font-size:30px ;
background-color: #1AA;
border-radius: 50%;
padding: 1px 8px;

  }