/* footer.css */

.footer {

  color: white;
  /* padding: 5rem; */
  
  /* left: 0;
  bottom: 0; */
  width: 100%;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  gap: 90px;
  background-color: #7F164F;
  width: 100%;
  padding: 5rem;
}

.footer-column-main {
  display: flex;
  justify-content: space-between;
  gap: 70px;
}

.footer-column h3 {
  margin-bottom: 1rem;
}

.footer-column p {
  margin-bottom: 0.5rem;
}

.footer-column a {
  color: #ffffff7a;
  text-decoration: none;
}

.footer-subscribe-section-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.footer-subscribe-section {
  width: 38%;
  background-color: #00000024;
  padding: 25px;
  border-radius: 15px;
  display: flex;
  height: fit-content;
}

.footer-subscribe-section-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-subscribe-section-input{
  display: flex;
   
}
.footer-subscribe-section-input input {
  border: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  width: 100%;
}

.footer-subscribe-section-input button {
  padding: 10px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #7F164F;
  font-size: 20px;
  width: 20%;
  color: white;
}

.footer-subscribe-section-para {
  color: #ffffff7a;
}

.footer-subscribe-section-para small {
  text-align: justify;
}

.footer-column1 a,
.footer-column2 a,
.footer-column3 a {
  color: #ffffff7a;
  text-decoration: none;
}

.footer-column3 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-column2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-column1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-below-section{
  padding: 0 5rem;
  /* margin-top: 50px; */
}
.footer-below-section-1{
  
  /* margin-top: 10px; */
}

.footer-below-section-sub{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-below-icon-main{
  display: flex;
  gap: 10px;
  color: black;
}
.footer-below-icon-main a{
  color: black;
}
.footer-below-icon{
  padding: 5px 9px;
  border: 1px solid black;
  width: fit-content;
  border-radius: 100%;
}
.footer-copyright {
  text-align: center;
  padding-top: 10px;
  font-size: small;
  position: absolute; /* Position the copyright text at the bottom */
  bottom: -10; /* Adjust as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
}
.footer-below-section-sub-img img{
  width: 100px;
}
/* Responsive styles */
@media only screen and (max-width: 768px) {
  .footer {
    /* padding: 30px 20px 50px; */
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column-main {
    flex-wrap: wrap;
    gap: 20px;
  }

  .footer-subscribe-section {
    width: 100%;
  }
  .footer-below-section{
    padding: 0 1rem;
    /* margin-top: 50px; */
  }
}


@media (max-width:290px) {

  .footer-below-icon-main{
    font-size: 13px
  }
}