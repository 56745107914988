/* 

.delete-account-main{
  padding: 20px;
    margin: auto;
    background-color: #1AA79E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    width: 500px;
}

.delete-account{
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin: 40px;
}


.delete-account-1{
    text-align: center;
    font-size: 100px;
    background-color: white;
    border-radius: 100%;
    width: 14%;
}

.delete-account-2 input{
   padding: 15px;
  border-radius: 20px;
  border: none;
  width: 80%;
} */

.delete-account-div{
    margin: 30px;
}
.delete-account-main {
    padding: 20px;
    margin: auto;
    background-color: #158f86;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center horizontally */
    border-radius: 20px;
    width: 80%; /* Adjusted width for responsiveness */
    max-width: 500px; /* Added max-width for larger screens */
    box-shadow: 1px 5px 10px 8px rgb(237, 235, 235);
  }
  
  .delete-account {
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center; /* Center horizontally */
    margin: 40px 0; /* Adjusted margin for responsiveness */
  }
  
  .delete-account-1 {
    font-size: 60px; /* Adjusted font size for responsiveness */
    background-color: white;
    border-radius: 50%; /* Changed from 100% to 50% for a circular shape */
    padding: 40px; /* Increased padding for better visibility */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-account-1 i {
    color: #1aa79e; /* Changed icon color to match background */
  }
  
  .delete-account-2 input {
    padding: 15px;
    border-radius: 20px;
    border: none;
    width: 100%;
    /* max-width: 400px;  */
  }
  
  .delete-account-3 {
    color: white;
    font-size: 14px; /* Adjusted font size for responsiveness */
    cursor: pointer;
    text-decoration: underline;
  }
  
  .delete-account-4 button{
   /* border: none; */
   background: none;
   color: white;
   border: 1px solid white;
   border-radius: 10px;
   padding:8px;
   transition: all ease 0.3s;
  }
  
  .delete-account-4 button:hover{
    background-color: white;
    color: black;
  }

  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .delete-account-1 {
      font-size: 40px;
      padding: 30px;
    }
  }
  