/* 
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 50%;
  }
  .login-image{
    text-align: center;
  }
  
  .login-section {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
  
  }
  
  .login-form{
    text-align: center;
  }
  
  
  
  .login-header h1 {
    margin-bottom: 10px;
    color: #333;
  }
  
  
  .login-header small {
    color: #666;
  }
  
  
  .form-group {
    margin-bottom: 20px;
  }
  
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  
  .form-input {
    width: 50%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
 

  .login-form button {
    width: 20%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #1AA79E;
    color: #fff;
    cursor: pointer;
  }
 
  .login-form button:hover {
    background-color: rgb(35, 127, 132);
  }
  
  .login-form small {
    display: block;
    margin-top: 10px;
    color: #007bff;
  }
   */


   /* Style the container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #f5f5f5; 
  margin-top: -140px
}

/* Style the login section */
.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  max-width: 600px; /* Limit the maximum width */
  /* background-color: #fff; */
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  margin: 50px;
}

.login-image{
  text-align: center;
}

/* Style the login image */
.login-image img {
  width: 50%;
  height: auto;
  border-radius: 10px 0 0 10px; /* Round the top left corner */
}

/* Style the login content */
.login-content {
  padding: 40px;

}

/* Style the login header */
.login-header h1 {
  margin: 0;
  font-size: 32px;
  color: #333;
}

/* Style the welcome message */
.login-header small {
  color: #666;
}

/* Style the form */
.login-form {
  margin-top: 20px;
}

/* Style the form group */
.form-group {
  margin-bottom: 20px;
}

/* Style the form label */
.form-label {
  font-size: 18px;
  color: #333;
}

/* Style the form input */
.form-input1 {
  width: 40vw;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Style the forget password link */
.login-form small {
  display: block;
  margin-top: 10px;
  color: #666;
}

/* Style the error message */
.error-message {
  color: red;
}


.login-form button:hover {
  background-color: #0056b3;
} 

/* Style the sign up message */
.login-form div {
  margin-top: 20px;
}

/* Style the sign up link */
.login-form a {
  color: #007bff;
  text-decoration: none;
}

/* Add hover effect to the sign up link */
.login-form a:hover {
  text-decoration: underline;
}


.input-with-icon {
  position: relative;
}

.form-input1 {
  padding-right: 2.5rem; /* Adjust as needed */
}

.eye-icon {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}


@media (max-width:768px) {
  .form-input1 {
    width: 100%;
  }
  .login-container {
   
    margin-top: -60px
  }
}
.submit_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  width: 100%;
  /* padding: 10px; */
  background-color: #1AA79E;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.button_loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
