.damage-pay-container {
    margin: 20px;
    padding: 50px;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
  
  .amount-input {
    /* flex: 1; */
    padding: 10px;
    margin-right: 10px;
    border: none;
  }

  .amount-input:focus{
    border: none;
  }
  
  /* .cancel-btn {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  } */
  
  .add-btn-div{
    margin: 20px;
    text-align: end;
    width: 60%;
  }
  .add-btn {
    padding: 10px 20px;
    background-color: #1AA79E;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .add-btn:hover, .cancel-btn:hover {
    opacity: 0.8;
  }
  