/* FirstStep.css */
.container-forget {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    margin: 100px auto ; /* Adjust margin-top as needed */
  }
  
  .title-forget {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .input-container-forget {
    display: flex;
    align-items: center;
  }
  
  .input-field-forget {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    width: 50vw;
  }
  
  .next-btn-forget {
    padding: 10px 20px;
    background-color: #1AA79E;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width:30vw;
  }
  
  .next-btn-forget:hover {
    background-color: #036d62;
  }
  .forget-mobile-btn{
    text-align: center;
  
  }

  .form-input2{
    width: 50vw;
    padding: 10px;
  }