.faqs-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
  }
  
  .faqs {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .faq {
    border-bottom: 1px solid #ccc;
  }
  
  .faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #1AA79E;
  }
  
  .faq-header:hover {
    background-color: #15837b;
  }
  
  .faq-body {
    padding: 10px;
  }
  
  .faqicon {
    font-size: 20px;
  }
  
  .faqactive {
    background-color: #e9e9e9;
  }
  