.address-selection-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media (max-width:768px) {
  .address-selection-container{
    flex-direction: column;
  }
  .address-card {
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
  .address-contain{
   flex-direction: column;
  }
  
}
.address-details {
  display: grid;
  /* width: 100%; */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}
.address-details button{
  border: none;
  font-size:20px ;
background-color: #1AA;
display: flex;
align-items: center;
justify-content: center;
gap: 5px;
/* border-radius: 60%; */
padding: 1px 8px;
}
.address-contain{
  display: flex;
  gap: 10px;
}
.address-details p {
  margin: 5px 0;
}
.address-card {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
  /* margin: 10px; */
  cursor: pointer;
  display: flex;
}

.address-card:hover {
  background-color: #f0f0f0;
}

.address-card.selected {
  background-color: #1AA79E;
  color: white;
}

.address-card h3 {
  margin-top: 0;
}

.address-card p {
  margin: 5px 0;
}

.proceed-to-checkout-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

  .product-item1{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px;
  }
   .product-item{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #e6e6e6;
    padding: 10px;
    border-radius: 10px;
    gap: 20px;
   }

   .product-item-img img{
    width: 100px;
   }

   .section-container p{
    margin: 10px;
    font-size: 18px;
    font-weight: 600;
  color: red;
  display: flex;
  gap: 5px;

}

.shipping-to-section h4{
   margin: 10px;
}
.section-container1{
  cursor: pointer;
    display: flex;
    gap: 8px;
}

.section-container .selected {
  color: blue;
}

.labour-charges {
  color: gray;
  background-color: #e6e6e6;
  padding: 10px;
  border-radius: 10px;
}

/* .section-container .selected + .labour-charges {
  display: block;
} */


.shipping-section{
    background-color: #e6e6e6;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
}

.shipping-section-items{
    display: flex;
    justify-content: space-between;
}
.shipping-section-items-1{
   font-weight: bold;
}
.shipping-section-deliverycharges{
    display: flex;
    justify-content: space-between;
}
.shipping-section-deliverycharges-1{
    font-weight: bold;
 }
.shipping-section-gst{
    display: flex;
    justify-content: space-between;
}
.shipping-section-gst-1{
    font-weight: bold;
 }
.shipping-section-totalorder{
    display: flex;
    justify-content: space-between;
}
.shipping-section-totalorder-1{
    font-weight: bold;
 }


 /* .section-container-bottomsheet {
    cursor: pointer; 
  }
  
  .bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%; 
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
   
  }
   */

   .section-container-bottomsheet {
    /* margin: 10px; */
    cursor: pointer; 
  }
  .bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%; 
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow-y: auto;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    /* z-index: 999;  */
  }
  
  .best-available-offer{
    padding: 10px;
    border-radius: 10px;
    background-color: #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }

  .bx-chevron-right{
    font-size: 45px;
  }

.coupon-code-input {
    padding: 10px;
    border: none;
}

.coupon-code-section{
    display: flex;
    justify-content: space-between;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
}
.coupon-code-section button{
    color: #1AA79E;
    border: none;
    background:none;
}
.coupon-code-input:focus{
    border: none;
}


.final-price-section{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #e6e6e6;
    border-radius: 10px;
    margin: 10px;
}


.advance-payment-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #e6e6e6;
    border-radius: 10px;
    margin: 10px;
}

.advance-payment-arrow{
    font-size: 30px;
}

.options {
    display: flex;
    flex-direction: column;
  }
  
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  input[type="radio"] {
    margin-right: 8px;
  
  }
  
  /* Style for selected option */
  input[type="radio"]:checked + span {
    color: rgb(7, 152, 165); /* Change color when selected */
    font-weight: bold; /* Make text bold when selected */
  }
  

  .coupon-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.coupon-details {
    flex: 1;
}

.coupon-details h6 {
    margin: 0;
}

.coupon-details p {
    margin: 5px 0;
}

/* Hide the default radio button */
.coupon-radio {
  display: none;
}

/* Style the custom radio button */
.coupon-radio + label {
  display: inline-block;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  border-radius: 50%;
 
  border: 2px solid #079773; /* Example border color */
  cursor: pointer;
}

/* When the radio button is checked, change the background color */
.coupon-radio:checked + label {
  background-color: #1AA79E; /* Example color when checked */
}


.paynow-btn{
  text-align: center;
}

.paynow-btn button{
  border: none;
  padding: 15px;
  width: 50%;
  background-color: #1AA79E;
  color: white;
  border-radius: 10px;
}

.noaddress{
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}

.noaddress a {
  text-decoration: none;
  color: white;
  background-color: #1AA79E; /* Light background color */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px #50c8c0, 
              -5px -5px 8px #1AA79E; /* Soft shadow effect */
  transition: box-shadow 0.3s ease;
}

.noaddress a:hover {
  box-shadow: 3px 3px 6px #50c8c0, 
              -3px -3px 6px #1AA79E; /* Adjusted shadow on hover */
}

.Gst-optional-input{
 
  margin: 10px;
}

.Gst-optional-input input{
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid rgb(186, 186, 186);
}