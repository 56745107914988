.container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}


/* .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.item {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 10px; 
  transition: all ease-in-out 0.3s;
}


.image:hover {
  transform: scale(1.1);
}

.text {
  padding: 10px;
} */

.swiper {
  padding-top: 20px;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 500px;
  /* object-fit: contain; */
  border-radius: 20px;
}

/* CouponStyles.css */


@media only screen and (max-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }

  .image {
    width: 100%;
    height: auto;
    /* aspect-ratio: 4/3; */
    transition: all ease-in-out 0.3s;
    border-radius: 10px;
  }
  .container {
    margin: 10px 0px 5px;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 200px;
    /* object-fit: contain; */
    border-radius: 20px;
  }
}
