.forget-password-main{
    width: 35%;
    margin: auto;
}
.form-forget {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    padding: 30px;
    width: 450px;
    border-radius: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 50px auto;
  }
  
  ::placeholder {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .form-forget button {
    align-self: flex-end;
  }
  
  .flex-column > label {
    color: #151717;
    font-weight: 600;
  }
  
  .inputForm-forget {
    border: 1.5px solid #ecedec;
    border-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: 0.2s ease-in-out;
  }
  
  .input-forget {
    margin-left: 10px;
    border-radius: 10px;
    border: none;
    width: 85%;
    height: 100%;
  }
  
  .input-forget:focus {
    outline: none;
  }
  
  .inputForm-forget:focus-within {
    border: 1.5px solid #2d79f3;
  }
  
  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }
  
  .flex-row-forget > div > label {
    font-size: 14px;
    color: black;
    font-weight: 400;
  }
  
  .span-forget {
    font-size: 14px;
    margin-left: 5px;
    color: #2d79f3;
    font-weight: 500;
    cursor: pointer;
  }
  
  .button-submit-forget {
    margin: 20px 0 10px 0;
    background-color: #151717;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    cursor: pointer;
  }
  
  .button-submit-forget:hover {
    background-color: #252727;
  }
  

  @media (max-width: 768px) {
    .forget-password-main {
      width: 100%;
      padding: 0 10px; /* Adjust padding */
    }
  
    .form-forget {
      width: 100%;
    }
  }
  
  @media (max-width: 500px) {
    .forget-password-main {
   
      padding: 20px 20px; /* Adjust padding */
    }
    .inputForm-forget {
      padding: 10px; /* Remove padding */
    }
  
    .input-forget {
      width: calc(100% - 30px); /* Adjust width */
    }
  }

       