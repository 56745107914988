/* cart.css */

/* Base Styles */

.cart-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-transform: capitalize;

}
.cart-item {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  margin-bottom: 20px;
 
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.cart-item-details {
  flex: 1;
}

.cart-item-name {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.cart-item-quantity {
  margin-bottom: 5px;
}

.cart-item-price {
  margin-bottom: 10px;
}

.delete-btn {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.delete-btn:hover {
  background-color: #ff7875;
}


.cart-total {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-total-text {
  font-size: 18px;
}

.cart-total-price {
  font-size: 18px;
  color: #333;
}

.checkout-btn {
  padding: 10px 20px;
  background-color: #1AA79E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #389e98;
}

.cart-time-slots {
  margin-bottom: 20px;
}

.cart-time-slots h6 {
  font-size: 18px;
  margin-bottom: 10px;
}

.cart-time-slots label {
  display: block;
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
}

.cart-time-slots label input[type="radio"] {
  display: none;
}

.cart-time-slots label .radio-custom {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #666;
  border-radius: 50%;
  background-color: #fff;
}

.cart-time-slots label input[type="radio"]:checked + .radio-custom::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #1AA;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


label.disabled input[type="radio"] {
  cursor: not-allowed; 
}

label.disabled input[type="radio"] + .radio-custom {
  background-color: #9b9797; 
}


label input[type="radio"]:checked + .radio-custom::after {
  display: block; 
}

.infoabout-slot {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically centered */
}

.bg-1, .bg-2 {
  width: 20px;
  height: 20px;
  background-color: #1AA; /* Change color as needed */
  border-radius: 50%; /* Makes the element a circle */
}

.bg-2 {
  background-color: #9b9797; /* Change color as needed */
}

.av-slot {
  display: flex;
  align-items: center; /* Align items vertically centered */
  gap: 10px;
}

@media only screen and (max-width: 600px) {


  .cart-item-image {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .cart-item-details {
    text-align: center;
  }

  .cart-total {
    flex-direction: column;
    align-items: flex-end;
  }
  
.cart-container {

  padding-top: 100px;
}
}



input[type="date"] {
  padding: 8px;
  width: calc(50% - 40px);
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.cart-date-time-container {
  margin-bottom: 20px;
}

.cart-date-time-container h6 {
  font-size: 18px;
  margin-bottom: 10px;
}

.cart-time-slot {
  margin-bottom: 10px;
}

.cart-time-slot label {
  margin-right: 10px;
}

.checkout-btn-div {
  text-align: center;
}

.date-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.date-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}

.date-input:focus {
  border-color: dodgerblue;
}



.empty-cart-container button{
  border: none;
  background-color: #1AA;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.empty-container {

  width: 100vw;
  height: 100vh;
  --s: 200px; /* control the size */
  --c1: #1d1d1d;
  --c2: #4e4f51;
  --c3: #3c3c3c;

  background: repeating-conic-gradient(
        from 30deg,
        #0000 0 120deg,
        var(--c3) 0 180deg
      )
      calc(0.5 * var(--s)) calc(0.5 * var(--s) * 0.577),
    repeating-conic-gradient(
      from 30deg,
      var(--c1) 0 60deg,
      var(--c2) 0 120deg,
      var(--c3) 0 180deg
    );
  background-size: var(--s) calc(var(--s) * 0.577);
}
